import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileTextColorScheme": "light",
  "tileImage": "./tile-image-01.png",
  "types": ["system", "frontend", "backend", "product"],
  "status": ["completed"],
  "title": "ITS Green",
  "subtitle": "Intelligent Traffic System",
  "description": "Intelligent traffic system to streamline commuting for bicyclists through light controlled intersections.",
  "years": ["2019"],
  "institutions": ["dtu", "idemolab"],
  "location": "cph",
  "keywords": {
    "topics": ["iot", "api", "ux"],
    "tools": ["python", "adobe xd", "raspberry pi", "phillips hue", "particle photon", "wiring", "c/c++", "flask", "swift", "boostrap"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }, {
    "name": "Sebastian Fohlman",
    "role": "Designer & Developer"
  }, {
    "name": "Mikkel Leth Olsen",
    "role": "Supervisor"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # ITS Green */
    }
    <h2>{`Description`}</h2>
    <p>{`A `}<strong parentName="p">{`I`}</strong>{`ntelligent `}<strong parentName="p">{`T`}</strong>{`raffic `}<strong parentName="p">{`S`}</strong>{`ystem allowing users to follow the flow of the green light in intersections along Nørrebrogade.`}</p>
    <p>{`This I my graduation project from B.Eng IT-Electronics. In collaboration with Sebastian TODO we conceptualized, researched, designing, developed and implemented a working prototype of the system.`}</p>
    <p>{`As my graduation project from IT-Electronics at DTU, I managed to arrange working together with a friend from another education Proces & Innovation so we could make a project which both had … in technology and design.
My friend had a for a long time had an idea of making some system or product to exploit the light intersections in the city to ease his urban commutes. So we decided to work on with this as our point of entry.`}</p>
    <p>{`The product is a device which is attached to the bicycle helmet of the rider. Telling when to change the pace in order to arrive at light controlled intersections just in time of green light. The device will either tell the rider to slow down, speed up or stay at current pace.`}</p>
    <p>{`Our vision of the project is to be implemented in trucks.`}</p>
    <p>{`The solution can reduce the energy needed to accelerate after being static or slow moving after having to stop at a light controlled intersection. Instead the truck would be able to reduce speed long before the intersection and arrive just in time of green light, and glide through.`}</p>
    <p>{`Afgangsprojekt i samarbejde med IdemoLab
I et tværfaglig projekt med Proces & Innovation har vi designet og udviklet et intelligent cykeltrafik system der kan fortælle hvor hurtigt du skal køre for at nå det grønne lys.`}</p>
    <p>{`ITS Green Graduation project in collaboration with IdemoLab
I an interdisciplinary project with Process & Innovation we designed and developed an intelligent bicycling traffic system - To tell how fast you should go to catch the green light.`}</p>
    <p>{`Frontend - Particle Photon (C++) with LED color interface and iOS (swift) app that tracked the bicyclist motion and location.
Backend - Raspbian and Python Flask for webserver with a REST API. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored`}</p>
    <p>{`In my graduation project, my friend and I designed and developed a distributed intelligent traffic bicycle system. He oversaw UX and I did the IT. I developed a backend system based on Raspbian and Python Flask for webserver with a REST API. The backend communicated with an iOS (swift) app that tracked the bicyclist motion and location. A Particle Photon (C++) connected to the internet, where mounted on the bicyclists helmet and used to give instructions about the traffic through a simple LED color interface. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored. The functionality basically were about always being able to tell how fast or slow the bicyclist should go in order to enter every intersection at green light - We think the system could be expanded to vehicles to reduce energy consumption by removing the need to accelerate from a static point.`}</p>
    <p>{`In my graduation project, my friend and I designed and developed a distributed intelligent traffic bicycle system. He oversaw UX and I did the IT. I developed a backend system based on Raspbian and Python Flask. The backend communicated with an iOS (swift) app that tracked the bicyclist motion and location. A Particle Photon (C++) connected to the internet, where mounted on the bicyclists helmet and used to give instructions about the traffic through a simple LED color interface. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored. The functionality basically was about always being able to tell how fast or slow the bicyclist should go in order to enter every intersection at green light - We think the system could be expanded to vehicles to reduce energy consumption by removing the need to accelerate from a static point.`}</p>
    <p>{`I an interdisciplinary project with Process & Innovation we designed and developed an intelligent bicycling traffic system - To tell how fast you should go to catch the green light.`}</p>
    <p>{`ITS Green, graduation project in collaboration with IdemoLab
I an interdisciplinary project with Process & Innovation we designed and devel- oped an intelligent bicycling traffic system - To tell how fast you should go to catch the green light.`}</p>
    <p>{`Afgangsprojekt i samarbejde med IdemoLab
I et tværfaglig projekt med Proces & Innovation har vi designet og udviklet et intelligent cykeltrafik system der kan fortælle hvor hurtigt du skal køre for at nå det grønne lys.`}</p>
    <p>{`ITS Green Graduation project in collaboration with IdemoLab
I an interdisciplinary project with Process & Innovation we designed and developed an intelligent bicycling traffic system - To tell how fast you should go to catch the green light.`}</p>
    <p>{`Frontend - Particle Photon (C++) with LED color interface and iOS (swift) app that tracked the bicyclist motion and location.
Backend - Raspbian and Python Flask for webserver with a REST API. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored`}</p>
    <p>{`In my graduation project, my friend and I designed and developed a distributed intelligent traffic bicycle system. He oversaw UX and I did the IT. I developed a backend system based on Raspbian and Python Flask for webserver with a REST API. The backend communicated with an iOS (swift) app that tracked the bicyclist motion and location. A Particle Photon (C++) connected to the internet, where mounted on the bicyclists helmet and used to give instructions about the traffic through a simple LED color interface. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored. The functionality basically were about always being able to tell how fast or slow the bicyclist should go in order to enter every intersection at green light - We think the system could be expanded to vehicles to reduce energy consumption by removing the need to accelerate from a static point.`}</p>
    <p>{`Graduation Project 🚥
In my graduation project, my friend and I designed and developed a distributed intelligent traffic bicycle system. He oversaw UX and I did the IT. I developed a backend system based on Raspbian and Python Flask. The backend communicated with an iOS (swift) app that tracked the bicyclist motion and location. A Particle Photon (C++) connected to the internet, where mounted on the bicyclists helmet and used to give instructions about the traffic through a simple LED color interface. The backend also hosted a website (Python, HTML, CSS, JS) where everything could be monitored. The functionality basically was about always being able to tell how fast or slow the bicyclist should go in order to enter every intersection at green light - We think the system could be expanded to vehicles to reduce energy consumption by removing the need to accelerate from a static point.`}</p>
    <p>{`An intelligent traffic system for commuting bicyclists, consisting of a distributed and connected system. A backend with a virtual twin of the intersections, a front-end with real- time information about the cyclist and route, an iOS application to utilise the sensors on the phone, and an embedded device with an ambient display to give the cyclist driving instructions.`}</p>
    <p>{`I an interdisciplinary project with Process & Innovation we designed and developed an intelligent bicycling traffic system - To tell how fast you should go to catch the green light. Based on a distributed system of ios, embedded, linux and web.`}</p>
    <h2>{`Systems`}</h2>
    <h3>{`iPhone Application`}</h3>
    <p>{`iOS app to send mobile sensor informations to backend`}</p>
    <h3>{`Main Application Backend`}</h3>
    <p>{`Python backend and frontend on raspberry pi`}</p>
    <h3>{`Web Monitor Application`}</h3>
    <p>{`The system consists of a backend with a digital twin of the city matching in the light intersection rotations on the specific time and day.`}</p>
    <h3>{`Ambient Display`}</h3>
    <p>{`Device based on Particle and LEDs programmed in wiring and C/C++
Embedded Device with.`}</p>
    <p>{`A iPhone application to collect data from the sensors and push to the backend, in order to calculate instructions through.`}</p>
    <h2>{`Activities`}</h2>
    <ul>
      <li parentName="ul">{`During the project we did all the design practices from the studies.`}</li>
    </ul>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Report`}</li>
      <li parentName="ul">{`Github Repositories`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/613893189"
        }}>{`Video`}</a></li>
      <li parentName="ul">{`Presentation`}</li>
      <li parentName="ul">{`Prototyping`}</li>
      <li parentName="ul">{`Project management`}</li>
      <li parentName="ul">{`Problem solving`}</li>
      <li parentName="ul">{`Research`}</li>
      <li parentName="ul">{`Interaction design`}</li>
      <li parentName="ul">{`Interface design`}</li>
      <li parentName="ul">{`Product design`}</li>
      <li parentName="ul">{`C/C++ programming for Particle`}</li>
      <li parentName="ul">{`Python for Raspberry Pi`}</li>
      <li parentName="ul">{`REST API`}</li>
      <li parentName="ul">{`Linux`}</li>
      <li parentName="ul">{`Web development for Python Flask website`}</li>
      <li parentName="ul">{`Swift in Xcode for iPhone`}</li>
      <li parentName="ul">{`Illustration`}</li>
      <li parentName="ul">{`Mentionable Results`}</li>
      <li parentName="ul">{`Links / References`}</li>
      <li parentName="ul">{`PPTX`}</li>
      <li parentName="ul">{`And for the IT part I did a distributed system with several code languages, all connected and talking together through REST APIs.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      